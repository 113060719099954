<template>
  <div class="details" id="details">
    <!--  标题栏  -->
    <top-nav :topNavData="topNavData" />
    <video
      class="video"
      id="audios"
      poster="https://image.iriding.cc/video/detailVideo.png"
      x5-video-player-type="h5-page"
      webkit-playsinline="true"
      playsinline="true"
      disablePictureInPicture
      muted
      width="100%"
      height="100%"
      controls
      controlsList="nofullscreen nodownload noremote footbar noplaybackrate"
    >
      <source
        src="https://image.iriding.cc/video/detailVideo.mp4"
        type="video/mp4"
      />
      您的浏览器不支持Video标签。
    </video>

    <!--  简介  -->
    <div class="brief" id="brief">
      <div id="imgOne" class="item">
        <div class="top">
          <span class="line1 remarkContainer">
            <img src="@/assets/image/accessorires2/img1-1.png" alt="" />
            <span>{{ $t("details.v16a") }}</span>
            <span class="remark">「2」</span>
          </span>
          <span class="line2">{{ $t("details.v14") }}</span>
          <div class="line3">{{ $t("details.v998") }}</div>
          <span class="line4">
            {{ $t("details.v15") }}{{ $t("details.v16") }}
          </span>
        </div>
        <div class="bottom">
          <div>
            <span>{{ $t("details.v1611a") }}</span
            ><span class="num">5</span>{{ $t("details.v1631b") }}
          </div>
          <div>
            <span>{{ $t("details.v1711a") }}</span
            ><span class="num">5</span><span>{{ $t("details.v1721a") }}</span>
          </div>
        </div>
        <img src="@/assets/image/accessorires2/img1.png" />
      </div>
      <div class="item">
        <div class="top">
          <div class="line1">
            <img src="@/assets/image/accessorires2/phone.png" alt="" />
            <span>{{ $t("details.v19") }}</span>
          </div>
          <span class="line2">{{ $t("details.v216") }}</span>
          <div class="line3">{{ $t("details.v18") }}</div>
          <span class="line4">
            {{ $t("details.v20") }}{{ $t("details.v21") }}<br />{{
              $t("details.v22")
            }}
          </span>
        </div>
        <div class="bottom"></div>
        <img src="@/assets/image/accessorires2/img2.png" />
      </div>
      <div class="item">
        <div class="top">
          <div class="line1">
            <img src="@/assets/image/accessorires2/qigang.png" alt="" />
            <span>{{ $t("details.v9") }}</span>
          </div>
          <span class="line2">{{ $t("details.v23") }}</span>
          <div class="line3">{{ $t("details.v231") }}</div>
          <span class="line4">
            {{ $t("details.v24") }}{{ $t("details.v25")
            }}{{ $t("details.v26") }}
          </span>
        </div>
        <div class="bottom">
          <div>
            {{ $t("details.v1611") }}<span style="font-size: 21px">16</span
            >{{ $t("details.v2631") }}
          </div>
          <div>
            {{ $t("details.v1711") }}<span style="font-size: 21px">63</span
            >{{ $t("details.v9973") }}
          </div>
        </div>
        <img src="@/assets/image/accessorires2/img3.png" />
      </div>
      <div class="item">
        <div class="top">
          <div class="line1">
            <img src="@/assets/image/accessorires2/qiya.png" alt="" />
            <span>{{ $t("details.v31") }}{{ $t("details.v311") }}</span>
          </div>
          <span class="line2">{{ $t("details.v272") }}</span>
          <div class="line3">{{ $t("details.v271") }}</div>
          <span class="line4">
            {{ $t("details.v30a") }}<br />{{ $t("details.v30b") }}
          </span>
        </div>
        <img src="@/assets/image/accessorires2/img4.png" />
      </div>
      <div class="item">
        <div class="top">
          <div class="line1">
            <img src="@/assets/image/accessorires2/jiaya.png" alt="" />
            <span>{{ $t("details.v176") }}</span>
          </div>
          <span class="line2 remarkContainer">
            <span>{{ $t("details.v177") }} </span>
            <span class="remark" style="color: white">「3」</span>
          </span>
          <div class="line3"></div>
          <span class="line4">
            {{ $t("details.v178") }}
          </span>
        </div>
        <div class="bottom">
          <div>{{ $t("details.v191") }}</div>
          <div style="display: flex; align-items: center">
            <div>{{ $t("details.v192") }}</div>
            &nbsp;<img
              src="@/assets/image/accessorires2/jiaya.png"
              alt=""
              style="width: 12px; height: 12px"
            />&nbsp;{{ $t("details.v193") }}
          </div>
        </div>
        <img src="@/assets/image/accessorires2/img5.png" />
      </div>
    </div>
    <!--    5种使用模式 -->
    <div class="content3">
      <div class="items2">
        <div class="t2">
          <img src="@/assets/image/accessories/(4).png" alt="" />
          <span class="items2-p1">{{ $t("details.v12") }}</span>
        </div>

        <span class="items2-p2">{{ $t("details.v2131") }}</span>
        <span class="items2-p4">{{ $t("details.v2132") }}</span>
        <span class="items2-p3"
          >{{ $t("details.v33") }}{{ $t("details.v34")
          }}{{ $t("details.v35") }}</span
        >
      </div>
      <div class="items3">
        <template v-for="(item, index) in imgs">
          <div
            :style="{
              backgroundImage: 'url(' + item.img2 + ')',
            }"
            class="items3-1"
            :key="'item-' + index"
          >
            <div class="left">
              <div>
                <img :src="item.img3" alt="" />
                <span class="p1">{{ $t(item.text1) }}</span>
              </div>
            </div>
            <div class="right">
              <div>
                <span class="p2">{{ $t(item.text2) }}</span>
                <span class="p1">{{ $t(item.text3) }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="items4">
        <div>
          <div class="top">
            <img src="@/assets/image/accessorires2/sm-buttery.png" alt="" />
            <span>{{ $t("details.v47") }}</span>
          </div>
          <div class="text2">{{ $t("details.v214a") }}</div>
          <div class="text4">{{ $t("details.v48") }}</div>
          <div class="text3">
            {{ $t("details.v49") }}{{ $t("details.v50")
            }}{{ $t("details.v51") }}
          </div>
        </div>

        <img
          class="img2"
          src="@/assets/image/accessorires2/buttery.png"
          alt=""
        />
      </div>
    </div>
    <!--    精致细节，为你的生活打气-->
    <div class="content6">
      <div class="content61">
        <div>
          <span class="item1">{{ $t("details.v52") }}</span>
          <div class="item2">
            <template v-for="(item, index) in mes">
              <div :key="'item2-' + index" class="item2-1">
                <div class="item1-f" v-if="index % 2 === 0">
                  <div
                    class="item2-left"
                    :style="{ 'margin-right': index == 2 ? '10px' : '5px' }"
                  >
                    <span class="item2-left-top remarkContainer">
                      <span>{{ $t(item.text1) }}</span>
                      <span v-if="index == 2" class="remark">「4」 </span>
                    </span>
                    <div class="item2-left-bottom">
                      <span>{{ $t(item.text2) }}</span>

                      <br />
                      <span>{{ $t(item.text3) }}</span>
                    </div>
                  </div>
                  <div
                    class="item2-right"
                    :style="{
                      backgroundImage: 'url(' + item.img + ')',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }"
                  >
                    <div v-if="index == 2">
                      <div>
                        <img src="/img/detail01.png" />
                        <span>{{ $t("details.v611") }}</span>
                      </div>
                      <div>
                        <img src="/img/detail02.png" />
                        <span>{{ $t("details.v612") }}</span>
                      </div>
                      <div>
                        <img src="/img/detail03.png" />
                        <span>{{ $t("details.v613") }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item2-e" v-else>
                  <div
                    class="item2-right"
                    :style="{
                      backgroundImage: 'url(' + item.img + ')',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }"
                  ></div>
                  <div class="item2-left">
                    <span class="item2-left-top">{{ $t(item.text1) }}</span>
                    <div class="item2-left-bottom">
                      <span>{{ $t(item.text2) }}</span>
                      <br />
                      <span>{{ $t(item.text3) }}</span>
                    </div>
                    <!--<div v-if="index = 1">
                      <img src="/img/detail01.png"/>
                    </div>-->
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!--      严苛测试-->
      <div class="content2a">
        <span class="item1a">
          <span class="remarkContainer">
            <span>{{ $t("details.v2121") }}</span>
            <sup>「5」</sup>
          </span>

          <span style="margin-left: 0px">{{ $t("details.v2122") }}</span>
        </span>
        <div class="item2a">
          <template v-for="(item, index) in c6">
            <div :key="'item3-' + index" class="t2">
              <div class="img1">
                <img :src="item.img1" alt="" />
                <span>{{ $t(item.text1) }}</span>
              </div>
              <div class="img1">
                <img :src="item.img2" alt="" />
                <span>{{ $t(item.text2) }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--    骑记充气宝P1性能参考-->
    <div class="content4">
      <div class="content4-1">
        <span class="item1">{{ $t("details.v76") }}</span>
        <div class="item2">1bar=14.5psi=100kPa</div>
        <div class="d1">
          <div class="item3">
            <div class="item3-1">{{ $t("details.v78") }}</div>
            <div class="item3-2">
              <span class="remarkContainer" style="width: 38%">
                <span
                  style="
                    margin-left: -7px;
                    line-height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    margin-left: 5px;
                    width: 100%;
                  "
                  >{{ $t("details.v108") }}<sup>「6」</sup></span
                >
                <!--                <span class="remark">「6」</span>-->
              </span>
              <div style="width: 32%">{{ $t("details.v119") }}</div>
              <div style="width: 30%">{{ $t("details.v134") }}</div>
            </div>
          </div>
        </div>
        <div class="d2">
          <div
            class="item4"
            :key="'item4-' + index"
            v-for="(item, index) in referInfo"
          >
            <div class="item4-1">
              <!--              <div class="img1">-->
              <img :src="item.img" alt="" />
              <!--              </div>-->

              <span class="item4-text1">{{ $t(item.text1) }}</span>
              <span class="item4-text2">{{ $t(item.text2) }}</span>
            </div>
            <div class="item4-2">
              <span v-for="child in item.text3" :key="child">{{
                $t(child)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d3">
        <div>
          <template v-for="(item, index) in obj">
            <span :key="'span-' + index"
              >{{ $t(item.text1) }}&nbsp;{{ $t(item.text) }}</span
            >
            <br :key="'br-' + index" />
          </template>
        </div>
      </div>
    </div>
    <div class="de" id="de"></div>
    <!--    骑记充气宝P1 规格参数-->
    <div class="content5" id="c5">
      <span class="p">{{ $t("details.v195") }} {{ $t("details.v5") }}</span>
      <div class="item-content-left">
        <img class="img1" src="/img/controller1.png" alt="" />
      </div>

      <div class="item-content-right">
        <table border="1" style="border: 0.5px solid #333333" cellspacing="0">
          <template v-for="(item, index) in c5">
            <tr :key="'tr-' + index">
              <td
                style="
                  width: 100px;
                  text-align: left;
                  padding-left: 15px;
                  border: 0.5px solid #333333;
                "
              >
                {{ $t(item.text1) }}
              </td>
              <td
                style="
                  text-align: left;
                  padding-left: 15px;
                  padding-right: 10px;
                  border: 0.5px solid #333333;
                "
              >
                {{ $t(item.text2) }}
                <span v-if="index == 2"> <br />{{ $t(item.text3) }} </span>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <!--      包装清单-->
      <div class="items2">
        <div class="p">
          <span>{{ $t("details.v175") }}</span>
        </div>

        <div class="footer2">
          <!--<div class="img1" style="width: 30%">
            <div class="imgs">
              <img
                style="width: 25px"
                src="@/assets/image/accessories/footer-1.png"
                alt=""
              />
            </div>
            <div class="text1">{{ $t("details.v174") }}</div>
          </div>
          <div class="img1">
            <div class="imgs">
              <img
                style="width: 18px"
                src="@/assets/image/accessories/footer-2.png"
                alt=""
              />
            </div>
            <div style="width: 120px" class="text1">
              {{ $t("details.v173") }}
            </div>
          </div>
          <div class="img1" style="width: 28%">
            <div class="imgs">
              <img
                style="width: 18.5px"
                src="@/assets/image/accessories/footer-3.png"
                alt=""
              />
            </div>
            <div class="text1">
              {{ $t("details.v172") }}
            </div>
          </div>-->
          <div class="img1">
            <div class="imgs">
              <img
                style="width: 25px"
                src="@/assets/image/accessories/footer-1.png"
                alt=""
              />
            </div>
            <div class="imgs" style="position: relative">
              <img
                style="width: 18px; position: absolute; bottom: 0"
                src="@/assets/image/accessories/footer-2.png"
                alt=""
              />
            </div>
            <div class="imgs" style="position: relative">
              <img
                style="width: 18.5px; position: absolute; bottom: 0"
                src="@/assets/image/accessories/footer-3.png"
                alt=""
              />
            </div>
          </div>
          <div class="img2">
            <div class="text1">
              <div>{{ $t("details.v174") }}</div>
            </div>
            <div class="text1">
              <div>{{ $t("details.v173") }}</div>
            </div>
            <div class="text1">
              <div>{{ $t("details.v172") }}</div>
            </div>
          </div>
        </div>
        <div class="footer3">
          <div class="img1">
            <div class="imgs" style="position: relative">
              <img
                style="width: 25.5px; position: absolute; bottom: 0"
                src="@/assets/image/accessories/footer-4.png"
                alt=""
              />
            </div>
            <div class="imgs">
              <img
                style="width: 5px"
                src="@/assets/image/accessories/footer-5.png"
                alt=""
              />
            </div>
            <div class="imgs" style="position: relative">
              <img
                style="width: 40.5px; position: absolute; bottom: 0"
                src="@/assets/image/accessories/footer-6.png"
                alt=""
              />
            </div>
            <div class="imgs" style="position: relative">
              <img
                style="width: 37px; position: absolute; bottom: 0"
                src="@/assets/image/accessories/footer-7.png"
                alt=""
              />
            </div>
          </div>
          <div class="img2">
            <div class="text1">
              <div>{{ $t("details.v171") }}</div>
            </div>
            <div class="text1">
              <div>{{ $t("details.v170") }}</div>
            </div>
            <div class="text1">
              <div>{{ $t("details.v169") }}</div>
            </div>
            <div class="text1">
              <div>{{ $t("details.v168") }}</div>
            </div>
          </div>
        </div>
        <!--<div class="footer">
          <div class="img1" style="width: 15%">
            <div class="imgs">
              <img
                style="width: 25.5px"
                src="@/assets/image/accessories/footer-4.png"
                alt=""
              />
            </div>
            <div class="text1">{{ $t("details.v171") }}</div>
          </div>
          <div class="img1">
            <div class="imgs">
              <img
                style="width: 5px"
                src="@/assets/image/accessories/footer-5.png"
                alt=""
              />
            </div>
            <div class="text1">{{ $t("details.v170") }}</div>
          </div>
          <div class="img1">
            <div class="imgs">
              <img
                style="width: 40.5px"
                src="@/assets/image/accessories/footer-6.png"
                alt=""
              />
            </div>
            <div class="text1">{{ $t("details.v169") }}</div>
          </div>
          <div class="img1">
            <div class="imgs">
              <img
                style="width: 37px"
                src="@/assets/image/accessories/footer-7.png"
                alt=""
              />
            </div>
            <div class="text1">{{ $t("details.v168") }}</div>
          </div>
        </div>-->
      </div>
    </div>
    <to-top />
  </div>
</template>


<script>
import ToTop from "../../component/totop/toTop.vue";
import TopNav from "../../component/product/topNav.vue";
import util from "@/util.js";
export default {
  name: "details",
  components: {
    ToTop,
    TopNav,
  },
  data() {
    return {
      topNavData: {
        showMenu: false,
        isShow: false,
        num: 0,
        type: "P1",
        img1: require("@/assets/image/accessorires2/c1.png"),
        title: "details.v1",
      },
      imgs: [
        {
          img: require("@/assets/image/accessorires2/img1.png"),
          id: 1,
          img2: require("@/assets/image/accessorires2/c3-1.png"),
          img3: require("@/assets/image/accessorires2/c3-img1.png"),
          text1: "details.v361",
          text2: "details.v3611",
          text3: "details.v37",
        },
        {
          img: require("@/assets/image/accessorires2/img2.png"),
          id: 2,
          img2: require("@/assets/image/accessorires2/c3-2.png"),
          img3: require("@/assets/image/accessorires2/c3-img2.png"),
          text1: "details.v38",
          text2: "details.v3612",
          text3: "details.v3711",
        },
        {
          img: require("@/assets/image/accessorires2/img3.png"),
          id: 3,
          img2: require("@/assets/image/accessorires2/c3-3.png"),
          img3: require("@/assets/image/accessorires2/c3-img3.png"),
          text1: "details.v40",
          text2: "details.v4111",
          text3: "details.v4121",
        },
        {
          img: require("@/assets/image/accessorires2/img4.png"),
          id: 4,
          img2: require("@/assets/image/accessorires2/c3-4.png"),
          img3: require("@/assets/image/accessorires2/c3-img4.png"),
          text1: "details.v42",
          text2: "details.v422",
          text3: "details.v423",
        },
        {
          img: require("@/assets/image/accessorires2/img5.png"),
          id: 5,
          img2: require("@/assets/image/accessorires2/c3-5.png"),
          img3: require("@/assets/image/accessorires2/c3-img5.png"),
          text1: "details.v44",
          text2: "details.v451",
          text3: "details.v462",
        },
      ],
      mes: [
        {
          img: require("@/assets/image/accessorires2/c4-1.png"),
          text1: "details.v53",
          text2: "details.v54",
          text3: "details.v55",
        },
        {
          img: require("@/assets/image/accessorires2/c4-2.png"),
          text1: "details.v56",
          text2: "details.v57",
          text3: "details.v581",
        },
        {
          img: require("@/assets/image/accessorires2/c4-3.png"),
          text1: "details.v59",
          text2: "details.v60",
          text3: "details.v61",
        },
        {
          img: require("@/assets/image/accessorires2/c4-4.png"),
          text1: "details.v62",
          text2: "details.v63",
          text3: "details.v631",
        },
        {
          img: require("@/assets/image/accessorires2/c4-5.png"),
          text1: "details.v64",
          text2: "details.v65",
          text3: "details.v66",
        },
      ],
      c5: [
        {
          text1: "details.v194",
          text2: "details.v195",
        },
        {
          text1: "details.v196",
          text2: "QJCQBP1",
        },
        {
          text1: "details.v198",
          text2: "details.v199a",
          text3: "details.v199b",
        },
        {
          text1: "details.v200",
          text2: "details.v201",
        },
        {
          text1: "details.v202",
          text2: "details.v203",
        },
        {
          text1: "details.v204",
          text2: "details.v2041",
        },
        // {
        //   text1: "details.v206",
        //   text2: "±1psi",
        // },
        {
          text1: "details.v208",
          text2: "details.v209",
        },
        /*{
          text1: "details.v210",
          text2: "5V---3A",
        },*/
      ],
      footer: [
        {
          img: require("@/assets/image/accessories/footer-1.png"),
          text1: "details.v174",
        },
        {
          img: require("@/assets/image/accessories/footer-2.png"),
          text1: "details.v173",
        },
        {
          img: require("@/assets/image/accessories/footer-3.png"),
          text1: "details.v172",
        },
        {
          img: require("@/assets/image/accessories/footer-4.png"),
          text1: "details.v171",
        },

        {
          img: require("@/assets/image/accessories/footer-5.png"),
          text1: "details.v170",
        },
        {
          img: require("@/assets/image/accessories/footer-6.png"),
          text1: "details.v169",
        },
        {
          img: require("@/assets/image/accessories/footer-7.png"),
          text1: "details.v168",
        },
      ],
      c6: [
        {
          img1: require("@/assets/image/accessories/c6-1.png"),
          text1: "details.v68",
          img2: require("@/assets/image/accessories/c6-2.png"),
          text2: "details.v69",
        },
        {
          img1: require("@/assets/image/accessories/c6-4.png"),
          text1: "details.v70",
          img2: require("@/assets/image/accessories/c6-5.png"),
          text2: "details.v71",
        },
        {
          img1: require("@/assets/image/accessories/c6-7.png"),
          text1: "details.v72",
          img2: require("@/assets/image/accessories/c6-8.png"),
          text2: "details.v73",
        },
        {
          img1: require("@/assets/image/accessories/c6-3.png"),
          text1: "details.v74",
          img2: require("@/assets/image/accessories/c6-6.png"),
          text2: "details.v75",
        },
      ],
      a: [],
      referInfo1: [
        {
          img: require("@/assets/image/accessories/c4-1.png"),
          text1: "details.v79",
          text2: "details.v80",
          text3: ["2.0-2.5bar", " ≈ 18条", "≈ 60 秒/条"],
          text4: "     ",
        },
        {
          img: require("@/assets/image/accessories/c4-3.png"),
          text1: "details.v81",
          text2: "（ 175/70 R14 ）",
          text3: ["0-2.5 bar", "≈ 5条", "≈ 4分 58 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-7.png"),
          text1: "details.v83",
          text2: "（ 195/65 R15 ）",
          text3: ["0-2.5 bar", "≈ 4条", "≈ 6分 3 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-2.png"),
          text1: "details.v85",
          text2: "（ 205/55 R16 ）",
          text3: ["0-2.5 bar", "≈ 4条", "≈ 5分 52 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-7.png"),
          text1: "details.v87",
          text2: "（ 215/55 R17 ）",
          text3: ["0-2.5bar", " ≈ 3条", "≈ 7分 20 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-11.png"),
          text1: "details.v89",
          text2: "（ 255/55 R20 ）",
          text3: ["0-2.5 bar", " ≈ 2条", "≈ 12分 30 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-5.png"),
          text1: "details.v91",
          text2: "（ 275/40 R19 ）",
          text3: ["0-2.5 bar", "≈ 3条", "≈ 9分 10 秒/条"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-15.png"),
          text1: "details.v93",
          text2: "（ 150/60 R17 ）",
          text3: ["0-2.4 bar", "≈ 7条", "≈ 2分 50 秒/条"],
        },

        {
          img: require("@/assets/image/accessorires2/cm.png"),
          text1: "details.v93",
          text2: "details.v96",
          text3: ["0-2.4 bar", "≈ 15条", "≈ 1分 30 秒/条"],
          text4: "",
        },

        {
          img: require("@/assets/image/accessories/c4-4.png"),
          text1: "details.v99",
          text2: "（ 700 x 25C ）",
          text3: ["0-120 psi", "≈ 16条", "≈ 63 秒/条"],
        },

        {
          img: require("@/assets/image/accessories/c4-6.png"),
          text1: "details.v99",
          text2: "（ 27.5 x 2.10 ）",
          text3: ["0-50 psi", "≈ 20条", "≈ 58 秒/条"],
        },

        {
          img: require("@/assets/image/accessories/c4-8.png"),
          text1: "电动滑板车轮胎",
          text2: "（ 8 1/2 x 2 ）",
          text3: ["0-40 psi", "≈ 201条", "≈ 10 秒/条"],
        },

        {
          img: require("@/assets/image/accessories/c4-10.png"),
          text1: "details.v103",
          text2: "（ 9 x 3.0-6 ）",
          text3: ["0-30 psi", "≈ 97条", "≈ 14 秒/条"],
        },

        {
          img: require("@/assets/image/accessories/c4-12.png"),
          text1: "details.v105",
          text2: "",
          text3: ["0-9 psi", "≈ 92个", "≈ 18 秒/个"],
        },

        {
          img: require("@/assets/image/accessories/c4-14.png"),
          text1: "details.v106",
          text2: "",
          text3: ["0-11 psi", "≈ 86个", "≈ 20 秒/个"],
        },

        {
          img: require("@/assets/image/accessories/c4-16.png"),
          text1: "details.v107",
          text2: "",
          text3: ["0-6 psi", "≈ 197个", "≈ 10 秒/个"],
        },
      ],
      referInfo: [
        {
          img: require("@/assets/image/accessories/c4-1.png"),
          text1: "details.v79",
          text2: "details.v80",
          text3: ["2.0-2.5bar", "details.v120", "details.v135"],
          text4: "     ",
        },
        {
          img: require("@/assets/image/accessorires2/y2.png"),
          text1: "details.v81",
          text2: "details.v82",
          text3: ["0-2.5 bar", "details.v121", "details.v136"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-5.png"),
          text1: "details.v83",
          text2: "（ 195/65 R15 ）",
          text3: ["0-2.5 bar", "details.v122", "details.v137"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-2.png"),
          text1: "details.v85",
          text2: "（ 205/55 R16 ）",
          text3: ["0-2.5 bar", "details.v122", "details.v138"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessories/c4-5.png"),
          text1: "details.v87",
          text2: "（ 215/55 R17 ）",
          text3: ["0-2.5bar", "details.v123", "details.v139"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessorires2/y6.png"),
          text1: "details.v89",
          text2: "（ 255/55 R20 ）",
          text3: ["0-2.5bar", "details.v124", "details.v140"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessorires2/y7.png"),
          text1: "details.v91",
          text2: "（ 275/40 R19 ）",
          text3: ["0-2.5bar", "details.v123", "details.v141"],
          text4: "",
        },
        {
          img: require("@/assets/image/accessorires2/y8.png"),
          text1: "details.v93",
          text2: "（ 150/60 R17 ）",
          text3: ["0-2.4bar", "details.v125", "details.v142"],
        },
        {
          img: require("@/assets/image/accessorires2/cm.png"),
          text1: "details.v93",
          text2: "（ 110/70 R17 ）",
          text3: ["0-2.4bar", "details.v126", "details.v143"],
        },
        {
          img: require("@/assets/image/accessorires2/y9.png"),
          text1: "details.v97",
          text2: "（ 700 x 25C ）",
          text3: ["0-120 psi", "details.v127", "details.v144"],
        },
        {
          img: require("@/assets/image/accessorires2/y10.png"),
          text1: "details.v99",
          text2: "（ 27.5 x 2.10 ）",
          text3: ["0-50 psi", "details.v128", "details.v145"],
        },
        {
          img: require("@/assets/image/accessorires2/y11.png"),
          text1: "details.v101",
          text2: "（ 8 1/2 x 2 ）",
          text3: ["0-40 psi", "details.v129", "details.v146"],
        },
        {
          img: require("@/assets/image/accessories/c4-10.png"),
          text1: "details.v103",
          text2: "（ 9 x 3.0-6 ）",
          text3: ["0-30 psi", "details.v130", "details.v147"],
        },
        {
          img: require("@/assets/image/accessories/c4-12.png"),
          text1: "details.v105",
          text2: "",
          text3: ["0-9 psi", "details.v131", "details.v148"],
        },
        {
          img: require("@/assets/image/accessories/c4-14.png"),
          text1: "details.v106",
          text2: "",
          text3: ["0-11 psi", "details.v132", "details.v149"],
        },
        {
          img: require("@/assets/image/accessorires2/y15.png"),
          text1: "details.v107",
          text2: "",
          text3: ["0-6 psi", "details.v133", "details.v150"],
        },
      ],
      obj: [
        { text1: "details.v1510", text: "details.v1570" },
        { text1: "details.v1520", text: "details.v1580" },
        { text1: "details.v1530", text: "details.v1590" },
        { text1: "details.v1540", text: "details.v1591" },
        { text1: "details.v1550", text: "details.v1592" },
        { text1: "details.v1560", text: "details.v1593" },
        { text1: "details.v165", text: "details.v166" },
      ],
      isShow: false,
      showMenu: false,
      activeIndex: false,
      num: 0,
      hh: false,
    };
  },
  mounted() {
    this.$store.state.isShowFooter = true;
    if (util.isSafari()) {
      this.hh = true;
    }
    window.addEventListener("scroll", this.getScroll);
  },
  methods: {
    getScroll() {
      this.topNavData.isShow = false;
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let imgoneTop =
        parseInt(
          document.querySelector("#imgOne").getBoundingClientRect().top
        ) - 50;
      if (imgoneTop < 0) {
        this.topNavData.showMenu = true;
        this.$store.state.isShowHeader = false;
      } else {
        this.topNavData.showMenu = false;
        this.$store.state.isShowHeader = true;
      }
      document.querySelector("#de").style.height = "0px";
    },
  },
};
</script>

<style scoped lang="less">
.details {
  width: 100%;
  height: 100%;
  padding-top: 52px;

  .de {
    width: 100%;
    height: 0px;
    padding-top: 50px;
    margin-top: -50px;
  }

  .top-44 {
    width: 100%;
    height: 108px;
    position: fixed;
    top: 53px;
    background: #fff;

    .item1 {
      margin: 0 auto;
      height: 35.5px;
      position: relative;

      span {
        line-height: 35.5px;
        display: inline-block;
        margin-left: 39px;
        font-size: 12px;
        font-family: MI LANTING;
        font-weight: 400;
        color: #3a3a3a;
      }

      .line {
        width: 306px;
        height: 0.5px;
        background: #3a3a3a;
        border: 0px solid #030000;
        opacity: 0.1;
        margin: 0 auto;
      }
    }
  }

  .content1 {
    width: 100%;
    position: relative;

    > img {
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
    }

    .top-1 {
      width: 100%;
      height: 50px;
      background: #f8f8f8;
      display: flex;
      flex-direction: initial;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      top: 0;
      > div {
        width: 100%;
        margin-left: 39px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: inline-block;
          font-family: MI LANTING;
          font-size: 13.5px;
          font-weight: 400;
          color: #3a3a3a;
        }

        img {
          width: 14px;
          height: 8px;
          margin-right: 30px;
        }

        /*button {
          width: 35px;
          color: #fff;
          font-size: 9px;
          height: 20px;
          background: #FF4D00;
          border: 0px solid #030000;
          opacity: 0.9;
          border-radius: 6px;
        }*/
      }
    }

    > div {
      width: 100%;
      height: 501px;
      /*border: 1px solid pink;*/
      display: flex;
      flex-direction: column;
      z-index: 1;
      top: 50px;
      position: absolute;

      .top-2 {
        width: 100%;
        background: #fff;

        .item1 {
          margin: 0 auto;
          position: relative;
          display: inline-block;
          span {
            width: 100%;
            line-height: 52.5px;
            display: inline-block;
            padding-left: 39px;
            font-size: 12px;
            font-family: MI LANTING;
            font-weight: 400;
            color: #3a3a3a;
          }

          .line {
            width: 306px;
            height: 0.5px;
            background: #3a3a3a;
            border: 0px solid #030000;
            opacity: 0.03;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .content2 {
    height: 216px;

    .video {
      width: 100%;
      object-fit: fill;
    }
  }

  .brief {
    padding-top: 50px;
    margin-top: -55px;
    .item {
      position: relative;
      /*height: 551px;*/
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin-top: -1px;

      > img {
        /*position: absolute;*/
        display: block;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        z-index: 0;
      }

      .top {
        //for top
        position: absolute;
        color: white;
        z-index: 1;
        left: 25px;
        right: 25px;
        top: 54px;

        > div {
          display: flex;
          align-items: center;
        }

        .line1 {
          /*display: flex;*/
          align-items: center;
          font-size: 15px;
          /*margin-top: 54.5px;*/
          position: relative;

          > img {
            width: 21px;
            height: 21px;
          }

          > span:nth-child(2) {
            margin-left: 6.5px;
          }

          > span:nth-child(3) {
            color: white !important;
          }

          /*span{*/
          /*  position: absolute;*/
          /*  right:0;*/
          /*}*/
          margin-bottom: 15px;
        }

        .line2 {
          font-size: 26px;
          display: inherit;
        }

        .line3 {
          font-size: 26px;
        }

        .line4 {
          font-size: 15px;
        }
      }

      .bottom {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        color: #000000;
        font-weight: bold;
        left: 25px;
        right: 25px;
        bottom: 21px;

        > div {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          font-size: 12px;
        }
        > div:nth-child(2) {
          margin-top: 0px;
        }
      }

      &:nth-child(2) {
        .line1 {
          img {
            width: 11.5px;
            height: 24.5px;
          }
        }
      }

      &:nth-child(3) {
        .line1 {
          img {
            width: 18px;
            height: 18px;
          }
        }
      }

      &:nth-child(4) {
        .line1 {
          img {
            width: 19px;
            height: 19px;
          }
        }

        .bottom {
          color: white;
        }
      }

      &:nth-child(5) {
        .line1 {
          img {
            width: 16.5px;
            height: 16.5px;
          }
        }

        .bottom {
          color: white;
        }
      }
    }
  }

  .content3 {
    width: 100%;

    .items {
      height: 551px;
      position: relative;

      .img1 {
        position: absolute;
        margin-top: 50px;
        display: flex;
        margin: 0 auto;
        margin-left: 25px;
        flex-direction: column;

        .img1-item1 {
          margin-top: 246px;
          font-family: MI LANTING;

          .p1,
          .p2 {
            font-size: 12px;
            font-weight: bold;
            color: #000000;
          }

          .p3 {
            font-size: 24px;
            font-weight: bold;
            color: #000000;
          }

          .d2 {
            position: relative;
            bottom: 8px;
          }
        }

        .img1-item2 {
          color: #fff;
          margin-top: 50px;

          > div {
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;

            img {
              width: 21px;
              height: 21px;
              margin-right: 5px;
            }
          }

          > span {
            // 99
            font-size: 26px;
            font-family: MI LANTING;
            font-weight: bold;
            margin-top: 17px;
            display: inline-block;
          }

          .sp2 {
            font-size: 26px;
            font-family: MI LANTING;
            font-weight: bold;
            margin-top: 0;
          }

          .sp3 {
            margin-top: 3px;
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
            margin-right: 25px;
          }
        }
      }

      .img2 {
        height: 100%;
        width: 85%;
        margin-top: 220px;
        margin: 0 auto;
        color: #fff;
        position: relative;

        .img2-item1 {
          position: absolute;
          margin-top: 45px;

          .d1 {
            display: flex;
            align-items: center;
            height: 25px;

            img {
              height: 25.5px;
              width: 11.5px;
              margin-right: 5.5px;
            }

            > span {
              font-size: 15px;
              font-family: MI LANTING;
              font-weight: 400;
              margin-top: 9px;
            }
          }

          .img2-item2 {
            height: 60px;
            margin-top: 9px;
            font-size: 26px;
            font-family: MI LANTING;
            font-weight: bold;
          }

          .img2-item3 {
            display: inline-block;
            height: 72px;
            top: 11.5px;
            margin-top: 20.5px;
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
          }
        }
      }

      .img3 {
        position: absolute;
        margin-top: 52px;
        margin-left: 26px;
        margin-right: 26px;
        display: flex;
        flex-direction: column;
        color: #fff;
        justify-content: space-around;

        .img3-item1 {
          color: #000;
          font-size: 12px;
          font-family: MI LANTING;
          font-weight: 400;
          position: relative;

          .d1 {
            margin-top: 264.5px;
          }

          .d2 {
            position: absolute;
            bottom: -30px;
          }

          .p1 {
          }

          .p2 {
          }

          .p3 {
            font-size: 24px;
          }
        }

        .img3-item2 {
          img {
            width: 18px;
            height: 18px;
          }

          .p1 {
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
            color: #fff;

            img {
              margin-right: 7px;
            }
          }

          .p2 {
            margin-top: 8px;
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
            color: #fff;

            span {
              font-size: 26px;
              font-family: MI LANTING;
              font-weight: bold;
              color: #fff;
            }

            .p3 {
              font-size: 15px;
              font-family: MI LANTING;
              font-weight: 400;
              color: #fff;
              display: inline-block;
              margin-top: 3px;
            }
          }
        }
      }

      .img4 {
        position: absolute;
        margin-top: 51.5px;
        margin-left: 25px;
        margin-right: 25px;
        width: 85%;
        color: #fff;

        .img4-item1 {
          position: absolute;

          img {
            width: 19px;
            height: 19px;
            margin-right: 6.5px;
          }

          > span {
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
          }

          .img4-item2 {
            font-size: 26px;
            font-family: MI LANTING;
            font-weight: bold;

            .p1 {
              display: inline-block;
              margin-top: 10px;
            }
          }

          .img4-item3 {
            margin-top: 3px;
            font-size: 15px;
            font-family: MI-LANTING_GB-OUTSIDE-YS;
            font-weight: 400;
          }
        }
      }

      .img5 {
        position: absolute;
        margin-top: 51.5px;
        margin-left: 25px;
        margin-right: 25px;
        width: 85%;
        display: flex;
        flex-direction: column;
        color: #fff;
        justify-content: space-around;

        .img5-item2 {
          margin-top: 256px;

          > span:nth-child(0) {
            font-size: 15px;
            font-family: MI LANTING;
            font-weight: 400;
            color: #fff;
          }

          > span:nth-child(1) {
            font-size: 15px;
            font-family: MILanPro;
            font-weight: normal;
            color: #fff;
          }
        }

        .img5-item1 {
          font-size: 15px;
          font-family: MI LANTING;
          font-weight: 400;
          color: #fff;

          .d1 {
            img {
              width: 18.5px;
              height: 18.5px;
              margin-right: 4.5px;
            }
          }

          .d2 {
            font-size: 26px;
            font-family: MI LANTING;
            font-weight: bold;
            color: #fff;
            margin-top: 15px;
            display: inline-block;
            margin-bottom: 15px;

            .p2 {
              font-size: 15px;
              font-family: MI LANTING;
              font-weight: 400;
              color: #fff;
              display: inline-block;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .items2 {
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 23.5px;
      display: flex;
      flex-direction: column;

      .t2 {
        position: relative;
        margin-top: 53.5px;

        img {
          width: 18px;
          height: 18px;
        }

        .items2-p1 {
          font-size: 15px;
          position: absolute;
          display: inline-block;
          margin-top: 3px;
          font-family: MI LANTING;
          font-weight: 400;
          margin-left: 7px;
          color: #3a3a3a;
        }
      }

      .items2-p2 {
        margin-top: 20.5px;
        font-size: 26px;
        font-family: MI LANTING;
        font-weight: bold;
        color: #1a1a1a;
      }

      .items2-p4 {
        font-size: 26px;
        font-family: MI LANTING;
        font-weight: bold;
        color: #1a1a1a;
      }

      .items2-p3 {
        display: inline-block;
        margin-top: 3px;
        font-size: 15px;
        font-family: MI LANTING;
        font-weight: 400;
        color: #3a3a3a;
      }
    }

    .items3 {
      margin-left: 25px;
      margin-right: 25px;
      margin-top: -5px;

      .items3-1 {
        display: flex;
        width: 100%;
        height: 92.5px;
        font-size: 12px;
        margin-right: 2px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        font-size: 12px;
        color: #3a3a3a;
        font-weight: bold;
        @media screen and (max-width: 414px) {
          background-size: 100% auto;
          height: 105.5px;
        }
        @media screen and (max-width: 375px) {
          background-size: 100% auto;
          height: 92.5px;
        }
        @media screen and (max-width: 320px) {
          height: 76.5px;
        }

        .left {
          width: 102px;
          display: flex;
          justify-content: center;
          align-items: center;

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            word-break: break-all;
            margin-left: 19px;
            margin-right: 5px;

            img {
              width: 36.5px;
              height: 36.5px;
            }

            .p1 {
              display: inline-block;
              margin-top: 7.5px;
              line-height: 10px;
            }
          }
        }

        .right {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          margin-left: -5px;

          > div {
            display: flex;
            flex-direction: column;

            .p1 {
              margin-top: 5px;
            }
          }
        }
      }
    }

    .items4 {
      width: 100%;
      background: #f5f5f5;
      margin-top: 30px;
      font-size: 15px;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      > div {
        width: 86%;
        margin-left: 25px;
        margin-top: 51px;

        .top {
          display: flex;

          img {
            height: 18px;
            width: 11.5px;
            margin-right: 10.5px;
          }

          span {
            font-family: MI LANTING;
            font-weight: 400;
            color: #3a3a3a;
          }
        }

        .text2 {
          margin-top: 10px;
          font-size: 26px;
          font-family: MI LANTING;
          font-weight: bold;
          color: #1a1a1a;
        }

        .text4 {
          font-size: 26px;
          font-family: MI LANTING;
          font-weight: bold;
          color: #1a1a1a;
        }

        .text3 {
          margin-top: 4px;
        }
      }

      .img2 {
        width: 199px;
        height: 337px;
        margin: 0 auto;
        margin-top: 34px;
      }
    }
  }

  .content4 {
    width: 100%;

    .content4-1 {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;

      .item1 {
        margin-top: 34.5px;
        font-size: 21px;
        font-family: MI LANTING;
        font-weight: bold;
      }

      .item2 {
        width: 150px;
        font-size: 9px;
        height: 18.5px;
        margin-top: 9.5px;
        margin-bottom: 14px;
        background-image: linear-gradient(to right, #e7b689, #fcd5a8);
        text-align: center;
        line-height: 18.5px;
        border-radius: 11px;
      }

      .d1 {
        font-weight: bold;
        width: 100%;
        .item3 {
          width: 100%;
          display: flex;
          flex-direction: row;
          /*height: 26px;*/
          border-radius: 4px;
          text-align: center;
          float: left;
          margin-bottom: 5px;

          .item3-1 {
            width: 111px;
            background: #f2f3f4;
            line-height: 26px;
            border-radius: 4px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .item3-2 {
            flex: 1;
            /*line-height: 26px;*/
            /*height: 26px;*/
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-weight: bold;
            /*float: left;*/
            margin-left: 5px;
            flex-grow: 1;
            background: #f2f3f4;
            display: flex;
            align-items: center;
            > div {
              display: flex;
              justify-content: center;
              width: 33%;
              word-break: break-all;
            }
            > span {
              flex: 1;
              width: 33%;
              word-break: break-all;
            }
            .sup {
              margin-left: 49px;
            }
            .ff {
              margin-left: 30px;
            }
          }
        }
      }

      .d2 {
        /*margin-left: 25px;*/
        /*margin-right: 25px;*/
        font-size: 7px;

        .item4 {
          border-radius: 6px;
          float: left;
          margin-bottom: 5px;
          width: 100%;
          display: flex;
          flex-direction: row;

          .item4-1 {
            width: 111px;
            height: 70px;
            background: #f2f3f4;
            display: flex;
            border-radius: 6px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            /*.img1 {*/
            img {
              /*transform: scale(0.5);*/
              height: 16px;
            }
            /*}*/

            .item4-text1 {
              font-size: 9px;
              margin-top: 3px;
            }

            .item4-text2 {
              font-size: 9px;
              margin-top: -2px;
              text-align: center;
            }
          }

          .item4-2 {
            background: #f2f3f4;
            margin-left: 5px;
            border-radius: 6px;
            text-align: center;
            font-size: 9px;
            height: 70px;
            display: flex;
            align-content: space-around;
            align-items: center;
            /*line-height: 76.5px;*/
            /*float: left;*/
            flex: 1;
            span {
              display: inline-block;
              width: 33%;
              text-align: center;
              /*word-break:break-all;*/
              font-size: 9px !important;
            }
          }
        }
      }
    }

    .d3 {
      /*height: 330px;*/
      background: #f5f5f5;
      font-size: 12px;
      color: #3a3a3a;
      position: relative;
      padding-top: 23px;
      margin-top: 22px;
      padding-bottom: 23px;

      > div {
        margin-left: 25px;
        margin-right: 25px;
        /*position: absolute;*/
        /*margin-top: 23px;*/

        span {
          line-height: 20px;
        }
      }
    }
  }

  .content6 {
    .content61 {
      width: 100%;
      margin: 0 auto;

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .item1 {
          width: 100%;
          font-size: 26px;
          line-height: 30px;
          padding: 20px 0 20px 0;
          font-family: MI LANTING;
          font-weight: 400;
          color: #fff;
          background: #373737;
          display: inline-block;
        }

        .item2 {
          width: 100%;

          .item2-1 {
            float: left;
            background: #f5f5f5;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .item1-f {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;

              .item2-right {
                width: 50%;
                height: 136px;
                position: relative;
                margin-left: 5px;
                margin-right: 5px;
                > div {
                  display: flex;
                  position: absolute;
                  bottom: 5px;
                  > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: 12px;
                    > img {
                      width: 25px;
                      height: 25px;
                    }
                    > span {
                      font-size: 6px !important;
                      color: #7a7a7a;
                      margin-top: 6px;
                      display: inline-block;
                      line-height: 12px;
                    }
                  }
                }
              }

              .item2-left {
                width: 50%;
                margin-left: 5px;
                margin-right: 5px;

                .item2-left-top {
                  font-size: 15px;
                  font-family: MI LANTING;
                  font-weight: bold;
                  color: #3a3a3a;
                  display: inline-block;
                  /*line-height: 16px;*/
                  margin-bottom: 10px;
                }

                .item2-left-bottom {
                  span {
                    font-size: 10px;
                    font-family: MI LANTING;
                    font-weight: 400;
                    color: #3a3a3a;
                    display: inline-block;
                    line-height: 13px;
                  }
                }
              }
            }

            .item2-e {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;

              .item2-right {
                width: 50%;
                height: 137px;
              }

              .item2-left {
                width: 50%;

                .item2-left-top {
                  font-size: 15px;
                  font-family: MI LANTING;
                  font-weight: bold;
                  color: #3a3a3a;
                  display: inline-block;
                  line-height: 16px;
                  margin-bottom: 10px;
                }

                .item2-left-bottom {
                  span {
                    font-size: 10px;
                    font-family: MI LANTING;
                    font-weight: 400;
                    color: #3a3a3a;
                    display: inline-block;
                    line-height: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .content2a {
      /*width: 100%;*/
      /*height: 550px;*/
      background: #373737;
      color: #1a1a1a;
      text-align: center;
      padding-bottom: 23.5px;

      .item1a {
        padding-top: 45px;
        margin-bottom: 33.5px;
        font-size: 26px;
        font-family: MI LANTING;
        font-weight: bold;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #fff;
      }

      .item2a {
        margin-left: 43px;
        margin-right: 43px;
        color: #fff;
        font-size: 12px;
        font-family: MI LANTING;
        /*font-weight: bold;*/

        .t2 {
          display: flex;
          .img1 {
            display: flex;
            flex: 1;
            flex-shrink: 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:nth-child(1) {
              border-right: 0.5px solid white;
            }
            img {
              margin-top: 30.5px;
              height: 36px;
              width: auto;
            }
            span {
              margin-top: 15.5px;
              margin-bottom: 21.5px;
              max-width: 130px;
              word-break: break-all;
            }
          }
          &:nth-last-child(n + 2) {
            border-bottom: 0.5px solid white;
          }
        }

        /*.t2 {
            width: 144.5px;
            height: 98px;
          }*/

        /*.borderLeft {
            border-left: 0px solid pink;
          }

          .borderRight {
            border-right: 0px solid pink;
          }

          .borderBottom {
            border-bottom: 0px solid pink;
          }

          .borderTop {
            border-top: 0px solid pink;
          }*/

        /*div {
            width: 48%;
            float: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #fff;
            justify-content: center;
            vertical-align: middle;

            .img1 {
              border: 0;
              display: block;
              margin-bottom: 4px;

              span {
                display: inline-block;
                margin-bottom: 26.5px;
              }

              img {
                transform: scale(0.6);
              }
            }
          }*/
      }
    }
  }

  .content5 {
    text-align: center;

    .p {
      text-align: center;
      font-size: 21px;
      font-family: MI LANTING;
      font-weight: bold;
      color: #1a1a1a;
      margin-top: 26px;
      display: inline-block;
      margin-bottom: -3px;
    }

    .item-content-left {
      text-align: center;

      .img1 {
        width: 188px;
        height: auto;
        margin-top: 17.5px;
        margin-left: 40px;
      }
    }

    .item-content-right {
      margin-top: 15px;
      margin-left: 25px;
      margin-right: 25px;
      height: 305px;

      table {
        height: 280px;
        margin: 0 auto;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2f3032;

        tr {
          td {
          }
        }
      }
    }

    .items2 {
      padding-bottom: 35px;
      background: #f5f5f5;

      .p {
        font-size: 26px;

        font-weight: bold;
        text-align: center;

        span {
          margin-top: 26px;
          display: inline-block;
        }
      }

      .footer {
        font-size: 10px;
        margin: 0 auto;
        margin-top: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: space-around;

        .img {
          flex: 1;
          float: left;
          /*height: 125px;*/
          position: relative;
          text-align: center;
          /*margin-bottom: -7px;*/

          .imgs {
            /*height: 100px;*/
            display: flex;
            justify-content: center;
            width: 100%;

            img {
              /*position: absolute;*/
              bottom: 0;
            }
          }
        }

        .img1 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: 10px;
          /*width: 33%;*/

          /*.imgs {*/
          /*  height: 100px;*/
          /*  display: flex;*/
          /*  justify-content: center;*/
          /*  width: 100%;*/

          /*img {*/
          /*  transform: scale(0.55);*/
          /*  position: absolute;*/
          /*  bottom: 0;*/
          /*}*/
          /*}*/
          .text1 {
            margin-top: 6px;
            display: inline-block;
            word-break: break-all;
            width: 100%;
          }
        }
        &:nth-child(2) {
          .img1 {
            width: 33%;
          }
        }
        &:nth-child(3) {
          .img1 {
            width: 25%;
          }
        }
      }
      .footer2 {
        font-size: 12px;
        margin-left: 5px;
        margin-right: 5px;
        .img1 {
          display: flex;
          padding-bottom: 10px;
          .imgs {
            display: flex;
            justify-content: center;
            width: 32%;
          }
        }
        .img2 {
          display: flex;
          padding-bottom: 15px;
          .text1 {
            height: 100%;
            width: 32%;
            > div {
              word-break: break-all;
            }
          }
        }
      }
      .footer3 {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
        .img1 {
          display: flex;
          padding-bottom: 5px;
          .imgs {
            width: 23.5%;
            display: flex;
            justify-content: center;
          }
        }
        .img2 {
          display: flex;
          .text1 {
            height: 100%;
            width: 23.5%;
            > div {
              word-break: break-all;
            }
          }
        }
      }
    }
  }
  .fix {
    opacity: 0.9;
    position: fixed;
    top: 0px;
    z-index: 90;
  }

  .remarkContainer {
    display: inline-block;
    position: relative;
  }

  .remark {
    position: absolute;
    font-size: 7.5px;
    color: #212121;
    line-height: 9px;
  }
  .num {
    font-size: 21px;
  }
  sup {
    font-size: 60%;
    position: relative;
    top: -5px;
  }
}
</style>
